<template>
  <div>
    <v-sheet
      v-if="posData"
      class="ma-4 pa-8">
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
          lg="6"
          xl="6">
          <div class="px-4">
            <div class="d-flex align-center mb-6">
              <p class="text-h5 mb-0 mr-4">
                POS DETAIL
              </p>
              <v-btn
                class="mr-4"
                color="secondary"
                small
                :loading="loading"
                outlined
                :to="{ name: 'PosManagement', query: { warehouse: posData.warehouse.id } }">
                <v-icon left>
                  mdi-arrow-left
                </v-icon>
                BACK
              </v-btn>
              <v-btn
                color="secondary"
                :loading="loading"
                class="mr-4"
                small
                :to="{ name: 'EditPos', params: { id: posData.id } }">
                <v-icon left>
                  mdi-pencil
                </v-icon>
                EDIT
              </v-btn>
              <v-btn
                color="red"
                class="white--text"
                small
                :loading="loading"
                @click="confirmDelete">
                <v-icon left>
                  mdi-trash-can
                </v-icon>
                DELETE
              </v-btn>
            </div>
            <p><span class="font-weight-bold mr-4">Serial Number : </span> {{ posData.serialNumber }}</p>
            <p><span class="font-weight-bold mr-4">POS ID : </span> {{ posData.posId || '-' }}</p>
            <p>
              <span class="font-weight-bold mr-4">Registered Date : </span> {{ posData.registeredDate | dateFormat }}
            </p>
            <p><span class="font-weight-bold mr-4">Brand / Model : </span> {{ posData.brandModel || '-' }}</p>
            <p><span class="font-weight-bold mr-4">Status : </span> {{ posData.status.toUpperCase() }}</p>
            <p><span class="font-weight-bold mr-4">Warehouse : </span> {{ posData.warehouse.name || '-' }}</p>
            <p><span class="font-weight-bold mr-4">Create Date : </span> {{ posData.createdAt | dateTimeFormat }}</p>
            <p><span class="font-weight-bold mr-4">Store : </span> {{ posData.store.name.toUpperCase() || '-' }}</p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
          lg="6"
          xl="6"
          style="max-height: calc(100vh - 176px);">
          <v-card
            outlined
            class="pa-4"
            style="height: calc(100vh - 176px - 208px - 36px); overflow-y: scroll;">
            <v-timeline
              align-top
              dense>
              <v-timeline-item
                v-for="(noteData, index) in posData.notes"
                :key="index"
                color="secondary"
                small>
                <v-card white>
                  <v-card-text class="white text--primary">
                    <p>
                      <v-icon class="mr-2">
                        mdi-note
                      </v-icon>
                      <strong>{{ noteData.message }}</strong>
                    </p>
                    <p class="text-caption">
                      <v-icon class="mr-2">
                        mdi-clock
                      </v-icon>
                      {{ noteData.createdAt | dateTimeFormat }}
                    </p>
                    <div class="text-caption">
                      <v-icon class="mr-2">
                        mdi-account
                      </v-icon>
                      {{ noteData.createdBy.email }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card>
          <div class="text-center">
            <v-textarea
              v-model="note"
              placeholder="Note here"
              dense
              class="mt-4"
              outlined></v-textarea>
            <v-btn
              :disabled="!note"
              :loading="addNoteLoading"
              color="secondary"
              @click="addNote">
              <v-icon left>
                mdi-plus
              </v-icon>
              ADD
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import PosManagementProvider from '@/resources/PosManagementProvider'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      posManagementProvider: new PosManagementProvider(),
      posData: null,
      note: '',
      modalValue: false,
      loading: false,
      addNoteLoading: false
    }
  },
  async mounted () {
    this.loading = true
    await this.fetchById(this.$route.params.id)
    this.loading = false
  },
  methods: {
    ...mapActions({
      setModal: 'Components/setModal'
    }),
    async fetchById (id, onlyNoteUpdate = false) {
      try {
        const { data } = await this.posManagementProvider.getById(id)
        if (data && !onlyNoteUpdate) {
            this.posData = data
        } else if (data && onlyNoteUpdate) {
            this.posData.notes = data.notes
        }
      } catch (error) {
        console.error('fetchPOSById', error)
      }
    },
    async addNote () {
      if (!this.note) return
      this.addNoteLoading = true
      try {
        await this.posManagementProvider.addNote(this.posData.id, { note: this.note })
        await this.fetchById(this.posData.id, true)
        this.note = ''
      } catch (error) {
        console.error('addNote', error)
      }
      this.addNoteLoading = false
    },
    confirmDelete () {
      this.setModal({
        value: true,
        title: 'Remove POS',
        message: 'Do you want to remove the POS?',
        confirmText: 'REMOVE',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'CANCEL',
        onConfirm: () => this.deletePos()
      })
    },
    async deletePos () {
      this.loading = true
      try {
        await this.posManagementProvider.deleteById(this.posData.id)
        this.$router.push({ name: 'PosManagement', query: { warehouse: this.posData.warehouse.id } })
      } catch (error) {
        console.error('deletePos', error)
      }
      this.loading = false
    }
  }
}
</script>
